body {
  font-family: "Matter-Regular";
  color: #828282;
}

.react-flow__attribution {
  display: none;
}

.primary-color {
  color: #E4DEF5 !important;
}

.custom-input-box .MuiInputBase-multiline {
  background: #F3F4F5;
}

.custom-input-box .Mui-focused {
  background: #fff;
}

.remove-dropdown-icon::after {
  content: none;
}

.custom-dropdown input {
  height: 40px;
  font-family: Matter-Regular;
  background-color: #f3f4f5;
  border-radius: 4px;
  color: #000;
  outline: none;
  border: 1px solid #dfe1e4;
  font-size: 14px;
  padding: 13px 35px 13px 13px;
  max-width: 400px;
  position: sticky;
}

.custom-dropdown input:focus {
  border: 1px solid blue;
}

.custom-dropdown ul {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate3d(220px, 165px, 0px);
  border-radius: 8px;
  padding: 11px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(0, 0, 0, 0.1) 1px 1px 2px 2px;
  overflow: auto;
  background-color: rgb(255, 255, 255);
}

.custom-dropdown .scroll-box {
  overflow-y: scroll;
  max-height: 350px;
  overflow-x: hidden;
}

.custom-dropdown li {
  padding: 8px 0px;
}