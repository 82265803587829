.smart-option-container{
  /* border: 1px solid rgb(206, 212, 218); */
  background-color: #f5f5f5;
  padding: 20px;
  border-radius:5px;
  margin-bottom:30px;
}

.option-icon{
  background: #DAE0FD;
  align-items: center;
  width: 45px;
  height: 45px;
  display: flex;
  border-radius: 5px;
}