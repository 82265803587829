
.toastr-container {
  display: block;
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 9999;
  overflow-y: auto;
  max-height: 607px;
}

.toastr-div {
  display: flex;
  align-items: center;
  width: 332px;
  min-height: 71px;
  border-radius: 10px;
  padding-right: 3.1rem;
  margin-bottom: 1rem;
  position: relative;
}

.success {
  border-left: 6px solid #3BC279;
  background-color: #C5F7DD;
}

.info {
  border-left: 6px solid #226CF4;
  background-color: #CCE3FF;
}

.warning {
  border-left: 6px solid #E7A027;
  background-color: #FFE8C3;
}

.error {
  border-left: 6px solid #E8594D;
  background-color: #FFD0CB;
}

.custom {
  border-left: 6px solid #707584;
  background-color: #E9E8EB;
}

.close-btn-div {
  position: absolute;
  right: 16px;
  cursor: pointer;
}

.img-div {
  margin: 0 17px;
}

.img-div img {
  min-width: 25px;
  min-height: 25px;
}

.toast-title {
  font-family: 'Matter-SemiBold';
  font-size: 17px;
  margin: 0;
}

.toast-p {
  font-family: 'Matter-Light';
  font-size: 14px;
  color: #333;
  margin: 0;
}

.toast-content {
  margin: 12px 0;
}