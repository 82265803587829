.loader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  display: flex;
  z-index: 999999;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: black;
}

.loader-div {
  text-align: center;
}

.loader-div img {
  width: 150px;
  height: 150px;
}

.loader-div p {
  opacity: 1;
  color: white;
}

img {
  background-color: none !important;
}