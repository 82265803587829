.link-option-container{
  background-color: #f5f5f5;
  padding: 20px;
  border-radius:5px;
  margin-bottom:25px;
}

.link-icon{
    background: #DAE0FD;
    align-items: center;
    width: 50px;
    height: 45px;
    display: flex;
    border-radius: 5px;
}