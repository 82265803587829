#flow-dot-bg {
  background-image: url("../../../assets/images/dot-bg-w.png");
}

.chat-bubble {
  transition: all 0.5s;
  padding: 11px 0 11px;

  // &:hover {
  //   transform: scale(1.1);
  //   padding-left: 8px;
  //   padding-right: 8px;
  //   margin-top: 22px;
  //   margin-bottom: 8px;

  //   border: 0.1px solid #dadada;
  //   border-radius: 10px;
  //   box-shadow: 0px 3px 10px #00000029;
  // }
}

.chat-bubble-hover {
  transform: scale(1.03);
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 20px;
  margin-bottom: 20px;

  border: 0.1px solid #dadada;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 10px #00000029;
}

.chat-bubble-active {
  background-color: #E4DEF5;
  padding: 11px 5px 11px;
  border-radius: 5px;
}

.last-ele {
  border: 1px dashed #007bff;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin: 20px 0px;
  transition: all 0.5s;
  padding: 18px 11px;
  font-size: 18px;
  color: #007bff;
  font-size: 14px;
  font-family: 'Matter-Regular';

  &:hover {
    transform: scale(1.03);
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 22px;
    margin-bottom: 8px;
    border-radius: 10px;

    border: 1px solid #007bff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}

.last-ele.dropdown-toggle::after {
  display: none;
}

.custom-divider {
  visibility: hidden;
}

.divider-zoom {
  visibility: inherit;
}

.top-divider {
  position: absolute;
  top: -30px;
  left: 0px;
  width: 100%;
}

.bottom-divider {
  position: absolute;
  bottom: -30px;
  left: 0px;
  width: 100%;
}