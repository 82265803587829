.content {
  position: relative;
  height: 100%;
}

#canvas {
  position: absolute;
  width: 80%;
  height: 80%;
  top: 0;
  left: 0;

  background-repeat: repeat;
  background-size: 50px 50px;
  background-color: #F3F4F8;
  border-radius: 22px;
}

.flow-div {
  height: 100vh;
  width: 80vh;
}

.start {
  font-family: 'Rockford-Regular';
  font-size: 28px;
  z-index: 2;
  position: absolute;
  top: 45px;
  left: 70px;
  color: #333333;

}

.start-image {
  z-index: 1;
  position: absolute;
  top: 96px;
  left: 125px;
}

.start-elem {
  z-index: 1;
  position: absolute;
  top: 110px;
  left: 249px;
}
