.no-data-found {
  margin: 20% auto;
  width: 400px;
  height: 150px;
  text-align: center;

}

.title {
  color: #333333;
  font-family: 'Matter-Bold';
  font-size: 16px;
}

.description {
  color: #333333;
  font-family: 'Matter-Regular';
  font-size: 12px;
}

.alert {
  background-color: #FFEBDD;
  max-width: 100%;
  padding: 19px;
  border-radius: 20px;
  width: 82 px;
}