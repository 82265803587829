.handler {
  height: 100%;
  display: flex;
  padding: 5px 0 5px 10px;
  justify-content: left;
  border-radius: 2px;
  border-left: 2.5px solid;


  .circle {
    margin: auto 0;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
  }

  .content {
    font-family: 'Matter-Regular';
    font-size: 8px;
    text-align: left;
    padding-left: 10px;
    color: #222;
  }
}

.icons {
  font-size: 10px;
}

.node-title {
  margin: revert;
  padding-right: 8px;
  word-break: break-all;
}

.red {
  border-color: #AA263F;
}

.red-background {
  background-color: #F9D9DC;
  color: #AA263F;
}

.green {
  border-color: #3DA144;
}

.green-background {
  background-color: #D0F0D2;
  color: #3DA144;
}

.purple {
  border-color: #744DB7;
}

.purple-background {
  background-color: #E4DEF5;
  color: #744DB7;
}

.blue {
  border-color: #226CF4;
}

.blue-background {
  background-color: #DAE0FD;
  color: #226CF4;
}

.component-img {
  width: 16px;
}

.bp-edge {
  color: red;
}

.react-flow__edge-path {
  stroke-width: 3px !important;
  stroke: #D6D7D7 !important;
}

.react-flow__edge-path:hover {
  stroke: #333333 !important;
  cursor: pointer !important;
}

.bp-node {
  background-color: white;
  border-radius: 5px;
  height: 52px;
}

.bp-node-hover {
  transition: transform 0.5s;
}

.bp-node-hover:hover {
  box-shadow: 0px 3px 10px #00000029;
  transform: scale(1.2);

  .react-flow__handle-bp {
    min-height: 20px !important;
    min-width: 20px !important;
    bottom: -9px;
  }

  .unconnected-error {
    img {
      transform: scale(1.2);
    }
  }

  .target-connectable {
    transform: scale(1.2) translate(-40%);
    z-index: 2000;
  }

  .edit-options-parent {
    display: block;
  }
}

.plus {
  top: -7px;
  left: -6px;
  width: 16px;
  position: absolute;
}

.react-flow__handle-bp {

  transition: all 0.5s;
  background-image: url('../../../assets/images/plus.svg') !important;
  // background: none !important;
  border: none !important;
  min-height: 14px !important;
  min-width: 14px !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  cursor: pointer !important;
}

.react-flow__node {
  cursor: all-scroll !important;

  &:hover{
    z-index: 99999999999!important;
  }

}

.tool-tip {
  font-size: 8px;
  position: absolute;
  top: -13px;
  left: 4px;
  font-family: "Matter-Regular";
  color: #828282;
}

.react-flow__handle {
  height: 9px !important;
  width: 9px !important;
}

.target-connectable {
  z-index: 2000;
  background: #43cf00 !important;
  min-width: 17.5px;
  min-height: 17.5px;
  top: -10px !important;
  animation: pulse 2s infinite;
  transition: transform 0.5s;

}


.arrow {
  position: absolute;
  top: -18px;
  left: 53%;
  transform: translate(-50%, -50%);
}

.arrow span {
  display: block;
  width: 8px;
  height: 8px;
  border-bottom: 1px solid #43cf00;
  border-right: 1px solid #43cf00;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 3s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-2px, -2px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}



.arrow-source {
  position: absolute;
  bottom: -10px;
  left: 53%;
  transform: translate(-50%, -50%);
}

.arrow-source span {
  display: block;
  width: 8px;
  height: 8px;
  border-bottom: 1px solid #226CF4;
  border-right: 1px solid #226CF4;
  transform: rotate(45deg);
  margin: -10px;
  animation: animateSource 3s infinite;
}

.arrow-source span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow-source span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animateSource {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-2px, -2px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

.tool-tip-components {
  font-size: 8px;
  position: absolute;
  top: -13px;
  left: 4px;
  font-family: "Matter-Regular";
  color: #212529;
}

.unconnected-error {
  position: absolute;
  top: -14px;
  right: -9px;

  img {
    transition: transform 0.5s;
  }
}

.chatGPT {
  height: 100%;
  display: flex;
  padding: 5px 0 5px 10px;
  justify-content: left;
  border-radius: 2px;
  border-left: 2.5px solid;

  img {
    width: 30px;
    height: 30px;
    margin-top: 6px;
  }

  .content {
    font-family: 'Matter-Regular';
    font-size: 8px;
    text-align: left;
    padding-left: 10px;
    color: #222;
  }
}

.bubble-nodes-icons {
  background-color: #F3F4F5;
  padding: 10px;
  border-radius: 100%;
  text-align: center;
  border-radius: 50px;
  vertical-align: super;
  margin-top: 9px;
}

.re-position.dropdown-menu{
  top: 60px!important;
}