.delete-node-container{
  width: 550px;
  height: 350px;
  text-align:center;
  overflow:hidden;
  
}

.delete-node-child{
  padding: 5% 0;
  overflow: hidden;
}

.delete-node-cross-icon{
  position: fixed;
  margin-top: -15px;
  margin-left: 530px;
 
}

.delete-node-img-container{
margin-bottom:25px;
}


.delete-node-title{
  font-size: 25px;
  font-family: 'Matter-Bold';
}

.delete-node-content{
  text-align: center;
  margin: auto;
  width:80%
}

.delete-node-buttons{
margin-top:20px;
}