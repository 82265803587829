.edit {
  padding: 20px 0px 20px 20px;
}

.question-select {
  background-image: none !important;
  background: #DFE1E4;
  padding: 10px;
  border-radius: 5px;
  color: #A2A3A5;
  border: none;
}

select dropdown {
  background-color: #fff;
}

.icon-background {
  width: 40px;
  height: 40px;
  padding-top: 10px;
  text-align: center;
  border-radius: 5px;
}

.edit-component-icon {
  font-size: 20px;
  border: none;
  outline: none;
}

.edit-component-input {
  font-size: 14px;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
}

.edit-component-input:focus {
  border: none;
  outline: none
}

.title-container {
  margin-top: 20px;
}

.title-content {
  font-family: "Matter-Bold";
  color: #353535;
  font-size: 18px;
}

.message-container {
  margin-bottom: 15px;
}

.delay-text {
  margin-top: 10px;
  font-size: 14px;
}

.questions-dropdown-list {
  color: #797979 !important;
}

.checkbox-input {
  font-size: 14px;
}

.appointment-checkbox {
  margin-bottom: 10px;
}

.custom-checkbox-container {
  padding-left: 2px;
}

.custom-checkbox .MuiFormControlLabel-label {
  font-size: 14px;
}

.dark-description-text {
  font-weight: 900;
  color: #0b0b0b;
  letter-spacing: .3px;
}

.custom-input-box input {
  font-size: 14px !important;
  padding: 9.94px 14px !important;
}

/* .custom-input-box label {
  font-size: 14px !important;
}

.custom-input-box input {
  font-size: 14px !important;
} */

.custom-input-box .MuiSelect-select {
  font-size: 14px !important;
  padding: 9.94px 14px !important;
}

.custom-input-box textarea {
  font-size: 14px !important;
}

.custom-input-box-b {
  margin-bottom: 20px;
}

.custom-input-box-t {
  margin-top: 10px;
}