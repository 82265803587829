.component-container {
  transition: all 1s;
}

.component-container-center {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px
}

.component-button {
  color: #007bff;
  position: absolute;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #226cf4;
  width: 200px !important;
  min-width: 120px !important;
  height: 38px;
  font-family: Matter-Regular;
  font-size: 14px;
  transition: all 1s;
}

.component-button-center {
  background-color: #226CF4;
  color: #fff;
  transform: scale(1.1);
  position: relative;
  z-index: 10001;

  &:hover {
    transform: scale(1.2);
    box-shadow: 0px 3px 10px #00000029;
  }
}

.components-all {
  width: 200px;
  z-index: 10001;
  position: absolute;
  opacity: 0;
  transition: opacity 0.4s;
}

.list-group-item-all {
  padding: 9px 14px;
  height: 34px;
  border: none !important;
  color: #212529;
  text-decoration: none;
  background-color: #fff;

  &:hover{
    cursor: pointer!important;
    background-color: #E4DEF5;
  }
}

.flow-img-all {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.list-item-title-all {
  padding: 0;
  width: 100%;
  font-size: 12px;
  margin: 0px 0 0 8px;
  padding-right: 7px;
}

.components-all-center {
  width: 1102px;
  z-index: 10001;
  opacity: 0;
  transition: opacity 0.4s;
  height: 20px;
  margin: 15px auto 0;
}

.list-group-item-all-center {
  padding: 9px 14px;
  height: 34px;
  display: inline-block !important;
  border: none !important;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  width: 147px;
}

.bp-ul {
  border-radius: none;
  max-height: 80vh;
  overflow-y: auto;
  display: 'none';
  background-color: #fff
}

.getting-started {
  font-family: "Rockford-Regular";
  font-size: 28px;
  color: #333333;
}

.getting-started-img {
  width: 64px;
  position: absolute;
  top: 75px;
  left: 40px;
  -webkit-transform: rotate(90deg) scaleX(-1);
  transform: rotate(90deg) scaleX(-1);
}

.info-new-add {
  height: fit-content;
  background-color: #34B857;
  color: white;
  font-family: Matter-Light;
  font-size: 10px;
  margin: 0 0 0 32px;
  border-radius: 10px;
  padding: 3px 12px;
  position: absolute;
  right: 17px;
  top: 6px;
}