@font-face {
  font-family: "Matter-Medium";
  src: url('assets/fonts/Matter/Matter-Medium.ttf');
}

@font-face {
  font-family: "Matter-Bold";
  src: url('assets/fonts/Matter/Matter-Bold.ttf');
}

@font-face {
  font-family: "Matter-Light";
  src: url('assets/fonts/Matter/Matter-Light.ttf');
}

@font-face {
  font-family: "Matter-Regular";
  src: url('assets/fonts/Matter/Matter-Regular.ttf');
}

@font-face {
  font-family: "Matter-SemiBold";
  src: url('assets/fonts/Matter/Matter-SemiBold.ttf');
}

@font-face {
  font-family: "Rockford-Regular";
  src: url('assets/fonts/RockFord/rockford.ttf');
}

.react-flow__node-default.selectable.selected,
.react-flow__node-group.selectable.selected,
.react-flow__node-input.selectable.selected,
.react-flow__node-output.selectable.selected {
  box-shadow: 0 0 0 .5px #fff !important;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

*:hover::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 20px;
  background: #F3F4F5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.theme-button {
  min-width: 150px;
  width: 150px;
  padding: 10px 20px;
}

.description-headings {
  color: #494949;
  margin-bottom: 7px;
  font-size: 16px;
}

.theme-input {
  padding: 10px;
  background: #F3F4F5;
  font-size: 14px;
}

.theme-textarea{
  resize:none;
}

.size-14{
  font-size:14px !important;
}

/* Removing dropdowns from input type number*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-flow {
  border-radius: 10px !important;
}

.react-flow__controls {
  display: flex;
  left: 20px !important;
}

/* Slider css */
.bp-slider {
  width: 170px !important;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.9;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 10px;
  vertical-align: middle;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 17px;
  height: 17px;
  background: #226CF4;
  cursor: pointer;
  border-radius: 100%;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #226CF4;
  cursor: pointer;
}
/* Slider css ends */

.cursor-pointer{
  cursor: pointer!important;
}