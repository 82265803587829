.edgebutton {
  width: 20px;
  height: 20px;
  background: #eee;
  border: 1px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject {
  background-color: white;
  text-align: center;
  min-height: 15px;
  border-radius: 4px;
  z-index: 999999;
}

.icon-container {
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  z-index: 99999999;
}

.delete-icon {
  font-size: 14px;
  height: 15px;
  margin-top: 0.2rem;
}
