.gif-container{
  max-height: 350px;
  overflow-y: scroll;
  /* outline: 1px solid #CED4DA; */
  /* margin-top: 15px; */
  border-radius: 5px;
  background-color: #FFF;
  /* background-color: #F3F4F5; */
  margin-bottom: 20px;
}

.gif-card{
  width:132px;
  height:100px;
}

.gif-image-parent{
  height: '100%'; 
  padding: '0 10px';
  outline: '1px solid #CED4DA';
  margin: '10px';
  overflow-y: 'scroll';
  overflow-x:'hidden'
}

.gif-image{
  height:100%;
  width:100%;
}

.gif-image:hover{
  opacity: 0.7;
}

/* .uploadimag .file-input {
  width: 200px;
  height: 100px;
  opacity: 0;
  left: 0;
} */

.file-replace-input{
  width: 150px;
  height: 50px;
  opacity: 0;
  right:84px;
}

.badge-border .MuiBadge-overlapRectangular {
  width: 20px;
  height: 20px;
  background-color: black;
}