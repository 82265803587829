.chat {
  max-width: 87%;
  display: inline-block;
  background: #f6f6f6;
  position: relative;
  padding: 10px 15px;
  min-height: 40px;
  font-size: 14px;
  display: block;

}

.left-chat {
  background-color: #F3F4F5;
  border-radius: 40px 50px 40px 1px;
  margin-left: 6px;
  color: #212529;
  word-break: break-word;
}

.right-chat {
  background-color: #226CF4;
  border-radius: 40px 50px 1px 40px;
  float: right;
  color: #fff;
  margin-right: 12px;
}

.right-chat-option {
  border-radius: 40px 50px 1px 40px;
  float: right;
  color: #fff;
  margin-right: 12px;
  margin-bottom: 5px;
}

.left-chat-option {
  border-radius: 40px 50px 1px 40px;
  float: left;
  color: #fff;
  margin-left: 5px;
  margin-bottom: 8px;
}


.active-chat {
  background-color: #E4DEF5;
  color: #212529;
}

.edit-question-parent {
  left: 96%;
  top: -11px;

  position: absolute;
  z-index: 1;

  .edit-q-options {
    position: absolute;
    border: 0.1px solid #dadada;
    top: -3px;
    right: 0;
    justify-content: space-evenly;
    background-color: #fff;
    border-radius: 4px;
    height: 26px;
    z-index: 9999999;
    display: flex;
    padding: 0px 8px;

    span {
      font-size: 19px;
    }


    .edit-q-hover {
      padding: 3px 3px;

      &:hover {
        color: #226cf4;
      }
    }
  }
}

.bubble-icons {
  background-color: #F3F4F5;
  padding: 10px;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  // margin-right: 8px;
  text-align: center;
  border-radius: 50px;
  vertical-align: super;
  margin-top: 9px
}