.li-padding {
  padding: 10px 5px !important;

  div:hover {
    transform: none;
    box-shadow: none
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 3px 10px #00000029;
    background-color: #E4DEF5;
  }
}

ul {
  overflow-x: hidden;
}

.component-text {
  margin-left: 10px;
  font-size: 13px;
}