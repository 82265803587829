.flow-img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
}

.list-group-item {
  padding: 6px;
  height: 26px;
  border: none !important;

  &:hover{
    // cursor: pointer!important;
    background-color: #E4DEF5!important;
  }
}

.components-list {
  top: 60px;
  width: 100%;
  padding: 4px;
  z-index: 1000;
  outline: none;
  cursor: pointer;
  // max-height: 142px;
  // position: absolute;
  overflow-y: scroll;
  border-radius: 5px;
  scroll-behavior: smooth;
  background-color: #ffffff;

  &:hover {
    // box-shadow: 0px 3px 10px #00000029;
    // box-shadow: 10px 8px 1px #00000029
  }
}

.list {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0;
  padding: 10px;
}

.list-item {
  display: flex;
  justify-content: flex-start;
  height: 15px;
}

.list-item-title {
  padding: 0;
  width: 100%;
  font-size: 8px;
  margin: 0px 0 0 5px;
  padding-right: 7px;
}

hr {
  margin: 3px 0 !important;
  height: 0.5px !important;
  opacity: 0.25 !important;
  background-color: #888 !important;
}

.flow-search {
  height: 24px;
  border: none;
  font-size: 8px;
  margin: 4px 4px 0;
  outline: none;
  box-shadow: none !important;
}

.flow-search-box {
  width: 14px;
  top: 9px;
  left: 9px;
  z-index: 5;
}

.info-new-tree {
  height: fit-content;
  background-color: #34B857;
  color: white;
  font-family: Matter-Light;
  font-size: 8px;
  margin: 0 0 0 32px;
  border-radius: 10px;
  padding: 3px 12px;
  position: absolute;
  right: 17px;
  top: 3px;
}