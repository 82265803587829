.edit-options-parent {
  display: none;
  position: absolute;
  height: 24px;
  right: 0;
  top: -21px;
  width: 54px;
  cursor: pointer!important;

}

.edit-options {
  position: absolute;
  top: 0px;
  right: 0;
  width: 56px;
  justify-content: space-evenly;
  background-color: #fff;
  border-radius: 55px;
  height: 18px;
  display: flex;

  span {
    font-size: 10px;
    vertical-align: text-top;
  }

}

.edit-hover:hover {
  color: #226cf4;
}