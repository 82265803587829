.v1-components-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.components-group {
  padding: 5px 8px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;

  .components-old {
    padding: 0;
    width: 100%;
    font-size: 14px;
    font-family: 'Matter-Regular';
    margin: 3px 0 0 5px;
    padding-right: 7px;
    color: #212529;
  }

  .components-img-old {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    margin-top: 3px;
    vertical-align: unset;
  }
}

.flow-img-v1 {
  width: 20px;
  height: 20px;
  margin-top: 4px;
}

.title-v1 {
  background-color: #e9e9ef;
  position: absolute;
  padding: 10px;
  color: #212529;
  border-radius: 4px;
  width: 98%;
  left: 1%;
  text-align: center;
  height: 45px;
  z-index: 4;
}

body {
  overflow: -moz-scrollbars-vertical;
  overflow-y: hidden;
}

.scroll-content {
  overflow-y: scroll;
  overflow-x: hidden;
  // max-height: 90vh;
  // min-height: 90vh;
  height: 100%;
}

.v1-button-container {
  margin-top: 10px;
  width: 100%;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border: none;
  }

  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.v1-components-list {
  margin: 0 5px 0 2px;
}

.flow-list {
  padding: 0;
  // overflow-y: scroll;
  // max-height: calc(100vh - 10px);

  li {
    display: inline-block;
    padding: 0 3px 5px;
    width: 100%;
    cursor: pointer;

    div {
      padding: 9px;
      display: block;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
      color: #000;
      background: #fff;
      font-weight: 600;
      font-size: 13px;

      img {
        margin-right: 8px;
      }

      span {
        margin-top: 4px;
      }
    }
  }
}

.info-new {
  height: fit-content;
  background-color: #34B857;
  color: white;
  font-family: Matter-Light;
  font-size: 12px;
  margin: 0 0 0 32px;
  border-radius: 10px;
  padding: 3px 12px;
  position: absolute;
  right: 6px;
  top: 6px;
}

li {
  div:hover {
    transform: scale(1.02);
    box-shadow: 0px 3px 10px #00000029;
    background-color: #E4DEF5;

  }
}

ul {
  overflow-x: hidden;
}

.bg-color-hover:hover {
  background-color: #E4DEF5!important;
}